<template>
    <div>
        <section class="inner-banner">
            <div class="container text-center">
            <ul class="thm-breadcrumb">
                <li class="thm-breadcrumb__item"><router-link to="/">Home</router-link></li>
                <li class="thm-breadcrumb__item"><span>{{ title }}</span></li>
            </ul>
            <h2 class="inner-banner__title"><span>{{ title }}</span></h2>
            </div>
        </section>
        <!-- <hr /> -->
    </div>
</template>
<script>

  export default {
    name: 'PageTopSection',
    props: {
        title: String,
    },
    data() {
      return {

      };
    },

    components: {

    },

  }
</script>

<style scoped>
/* .inner-banner{background:#2e3d62 url('/img/banners/page-top-section.jpg') center top no-repeat;background-size: cover;padding-bottom:120px;padding-top:130px;} */
.inner-banner{background:green; padding-bottom:60px;padding-top:60px;}
.inner-banner__title{margin:0;color:#ffffff;font-weight:700;font-size:46px;}
.inner-banner__title span{position:relative;}
.inner-banner__title span:before{content:"";background-color:ffffff;opacity:0.102;width:100%;height:8px;position:absolute;bottom:12px;left:0;border-radius:4px;}
.inner-banner .container{display:-moz-flex;display:-ms-flex;display:-o-flex;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;}
.thm-breadcrumb{margin:0;padding:0;list-style:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;}
.thm-breadcrumb:before{content:"";background-color:ffffff;opacity:0.102;width:100%;height:8px;position:absolute;bottom:8px;left:0;border-radius:4px;}
.thm-breadcrumb__item a,.thm-breadcrumb__item span{font-size:18px;color:#ffffff;}
.thm-breadcrumb__item + .thm-breadcrumb__item:before{content:".";display:inline-block;vertical-align:middle;font-size:18px;color:#ffffff    ;margin-left:10px;margin-right:10px;}
</style>