<template>
  <div>
    <PageTopSection title="Privacy Policy" />
    <section class="blog-details">
        <div class="container">
          <div class="row">
            <div class="col-lg">
              <div class="blog-details__content">
                <!-- /image -->
<h1 style="text-align: center; line-height: 107%; break-after: avoid; font-size: 16pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 26.0pt; line-height: 107%; font-family: Arial, sans-serif;">Respectmart Acceptable Use Policy</span></strong></h1>
<br>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Please, carefully read the terms of our Acceptable Use Policy before you make use of our site and services. By making use of this website, you confirm your acceptance of the terms laid out in this policy and also confirm you will agree with them. If you disagree with these terms, you may not visit this website or make use of any services offered on the site. You may print these terms copy in case of future reference.</span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">What do the terms say?</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Our Acceptable Use Policy states the standards of content applicable when you upload content to this website, communicate with other uses of the site, link our site to other sites, or interact with the website in other ways.</span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Who are we? </span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Respectmart, with the website <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://respectmart.com/">https://respectmart.com/</a></span>, is a subsidiary of Afridext Integrated Services LLC., a registered firm in Nigeria. </span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">How can you contact us?</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You can contact our reps via email at <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="mailto:info@respectmart.com">info@respectmart.com</a></span> or phone call at +1(312)-420-3967.</span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">What other terms are applicable to you, our customer?</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Our <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://sell.respectmart.com/terms.html">Terms &amp; Conditions</a></span> also apply to your use of this website. </span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Changes to the terms of the Acceptable Use Policy</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We make one change or the other to these now and then. Therefore, every time you intend using this website, we recommend checking these terms to make sure you know any amendments made to them.&nbsp;&nbsp; </span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Ways your use of our site prohibited</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You may not make use of this website in the following ways:</span></p>
<ul style="margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">In any way and manner that disobeys valid local, national, or international regulation or law</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">In any unlawful or fraudulent manner or in a way that fraudulent or unlawful intent or effect</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">To insult, bully, coerce, humiliate, or intimidate any individual</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">With the intent to harm or attempting to cause harm to minors in any manner</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">To upload content belonging to terrorists</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">To transmit data intentionally or upload or send material with viruses, malware, Trojan horses, time-bombs, worms, or other malicious software programs that affect how a computer software or hardware works </span></li>
<li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">To procure the sending of, or transmit, unauthorized or unsolicited promotional or advertising material or any other type of spam (similar solicitation)</span></li>
</ul>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You also give your consent to the following terms:</span></p>
<ul style="margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Not to duplicate, copy, reproduce or resell any content on our website contravening our <span style="color: blue; text-decoration: underline;"><a style="color: blue; text-decoration: underline;" href="https://sell.respectmart.com/terms.html">Terms and Conditions</a></span> provisions. </span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Not to engage in unauthorized access, interference, disruption, or damage:&nbsp; &nbsp;</span></li>
</ul>
<ul style="list-style-type: circle; margin-bottom: 0in; margin-top: 0px;">
<li style="list-style: none; margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;">
<ul style="list-style-type: circle; margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">any part of this website</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">any software programs used in our website provision</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">any network or equipment&nbsp; that this website is stored on</span></li>
<li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">any network, equipment, or software programs used by or owned by third parties</span></li>
</ul>
</li>
</ul>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Breach of Acceptable Use Policy </span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">If we believe a breach of this policy has occurred, we may take any action that we see as appropriate. If you don’t abide by this policy, this is equivalent to a material breach of the Terms and Conditions that give you permission to use this website. Also, failure to comply with this Acceptable Use Policy may result in our implementing all or some of the actions below: </span></p>
<ul style="margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will withdraw immediately your right to use this website temporarily or permanently.</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will immediately remove any Contributions you posted on this website temporarily or permanently.</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will warn you.</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will initiate legal action to force you within the ambits of Nigerian law to reimburse all costs based on indemnity that resulted from the policy breach. (These costs include legal costs and reasonable administrative costs.)</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will take further action against you legally.</span></li>
<li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">We will disclose such information to law enforcement agencies depending on our discretion or as required by law.</span></li>
</ul>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">While responding to such policy breaches, we exclude our liability for every action we may take. We may take other actions not mentioned above, and we may take further action depending on how we regard the breach.&nbsp;&nbsp; </span></p>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">Standards of content</span></strong></h2>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">These Standards of Content are applicable to any and all material that you, the user, contribute to this website as well as any interactive services affiliated or associated with it. You must comply with these standards of content at all times. The Content Standards is also applicable to each aspect of any contribution and to the whole, too.</span></p>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Using our discretion, we will determine if a contribution does not comply with the Standard of Contents. A contribution must meet the following criteria: </span></p>
<ul style="margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">It has to be accurate</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Where it gives an opinion, it must be held genuinely</span></li>
<li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Contribution must abide with the Nigerian law and in any country where it is posted or uploaded from</span></li>
</ul>
<p style="margin: 0in 0in 8pt; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">A contribution must not do the following:</span></p>
<ul style="margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Include material on sexual abuse</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Promote sexually obscene content</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Promote illegal activity or content</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Be in court contempt</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Infringe on database right, copyright, or trademark of an individual</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Impersonate any individual </span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Misrepresent your identity or association with an individual</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Breach any legal responsibility or duty that is in the form of a debt to another person or organization, such as a duty of confidence or contractual duty</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Give a false impression that the Contribution comes from Respectmart if that is not really true</span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">Promote, advocate, or incite anyone to assist in committing a crime or committing such an act yourself; examples of such criminal acts are computer misuse and copyright infringement </span></li>
<li style="margin: 0in 0in 0.0001pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">To clear any doubt in your mind, kindly note that for any Contribution in video format:</span>
<ul style="list-style-type: circle; margin-bottom: 0in; margin-top: 0px;">
<li style="margin: 0in 0in 8pt 0px; line-height: 107%; font-size: 11pt; font-family: Calibri, sans-serif;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif;">You are prohibited from uploading a video that contains malicious or harmful material.</span></li>
</ul>
</li>
</ul>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><strong><span style="font-size: 16.0pt; line-height: 107%; font-family: Arial, sans-serif;">The country’s laws that apply to claims and disputes</span></strong></h2>
<br>
<h2 style="margin: 2pt 0in 12pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif; color: windowtext;">If you are a consumer user, kindly bear in mind that these Terms, all their components, and formation are governed by Nigerian law. Both we, Respectmart, and you, the user, agree Nigerian courts will have exclusive jurisdiction. </span></h2>
<br>
<h2 style="margin: 2pt 0in 0.0001pt; line-height: 107%; break-after: avoid; font-size: 13pt; font-family: 'Calibri Light', sans-serif; color: #2e74b5; font-weight: normal;"><span style="font-size: 14.0pt; line-height: 107%; font-family: Arial, sans-serif; color: windowtext;">If you are a business or commercial user, these Terms, all their components, and formation (including any non-contractual claims or disputes) are governed by Nigerian law. Both we, Respectmart, and you, the user, agree Nigerian courts will have exclusive jurisdiction.</span></h2>
              </div>
            </div>
            <!-- /.col-lg -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
  </div>
</template>

<script>

  import PageTopSection from '/src/components/sections/PageTopSection.vue'

  export default {
    name: 'ProhibitedList',
    metaInfo: function() {
        return {
            title: "Privacy Policy | Respectmart",
        }
    },
    data() {
        return {

        };
    },

    components: {
        PageTopSection
    },

  }
</script>
